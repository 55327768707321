<template>
  <div>
    <navTopVue :index="4"></navTopVue>

    <!-- 产品详情 -->
    <div class="card">
      <div class="box" v-if="page === 1">
        <div class="item" v-for="i in 6" :key="i">
          <img src="../assets/img/product/soft2.jpg" alt="" />
          <span style="margin-bottom: 0.0617rem">专业技术人才管理系统</span>
        </div>
      </div>
      <div class="box" v-if="page === 2">
        <div class="item" v-for="i in 6" :key="i">
          <img src="../assets/img/product/comm.jpg" alt="" />
          <span style="margin-bottom: 0.0617rem">专业技术人才管理系统</span>
        </div>
      </div>
      <div class="box" v-if="page === 3">
        <div class="item" v-for="i in 6" :key="i">
          <img src="../assets/img/product/soft13.png" alt="" />
          <span style="margin-bottom: 0.0617rem">专业技术人才管理系统</span>
        </div>
      </div>
      <div class="btn">
        <span @click="page == 1 ? 1 : page--">«</span>
        <span @click="page = 1" :class="page === 1 ? 'active' : ''">1</span>
        <span @click="page = 2" :class="page === 2 ? 'active' : ''">2</span>
        <span @click="page = 3" :class="page === 3 ? 'active' : ''">3</span>
        <span @click="page === 3 ? 3 : page++">»</span>
      </div>
    </div>

    <!-- 底部 -->
    <footerVue></footerVue>
  </div>
</template>

<script>
import navTopVue from "@/components/navTopVue.vue";
import footerVue from "@/components/footerVue.vue";
export default {
  components: {
    navTopVue,
    footerVue,
  },
  data() {
    return {
      page: 1,
    };
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 7.2222rem;
  margin: 0 auto;
  .box {
    height: 2.4074rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      box-sizing: border-box;
      width: 2.2222rem;
      height: 1.0802rem;
      border: 0.0062rem solid #eee;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      img {
        width: 100%;
        height: 0.8951rem;
      }
      span {
        font-size: 0.0802rem;
        margin: 8px 0;
      }
    }
  }
  .btn {
    display: flex;
    width: 1rem;
    height: 0.1975rem;
    margin: 0.1235rem auto;
    border: 0.0062rem solid #ddd;
    border-radius: 0.037rem;
    align-items: center;
    span {
      flex: 1;
      height: 0.1975rem;
      line-height: 0.1975rem;
      border-right: 1px solid #eee;
    }
    .active {
      background-color: #ffc601;
      color: #fff;
    }
    :hover {
      background-color: #ffc601;
      color: #fff;
    }
    span:last-child {
      border-right: 0;
    }
  }
}
@media (max-width: 768px) {
  .card {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    .box {
      height: 1200px;
      .item {
        flex-direction: column;
        width: 100%;
        height: 175px;
        img {
          height: 145px;
        }
      }
    }
    .btn {
      width: 162px;
      height: 31px;
      span {
        height: 31px;
        line-height: 31px;
      }
    }
  }
}
</style>
