<template>
  <div>
    <div class="navTop">
      <div class="container">
        <div class="nav-top-box">
          <div class="an" v-if="!up" @click="up = !up">
            <img src="../assets/img/up.png" alt="" />
          </div>
          <div class="an" v-else @click="up = !up">
            <img src="../assets/img/down.png" alt="" />
          </div>
          <img class="logo" src="../assets/img/ripsoft_logo.jpg" alt="" />
          <ul class="hidden">
            <li :class="index === 1 ? 'active' : ''">
              <a href="/">首页</a>
            </li>
            <li :class="index === 2 ? 'active' : ''">
              <a href="/CompanyProfile">公司介绍</a>
            </li>
            <li :class="index === 3 || index === 8 ? 'active' : ''">
              <a href="/ProductServices">产品服务</a>
            </li>
            <li :class="index === 4 ? 'active' : ''">
              <a href="/SuccessAnli">成功案例</a>
            </li>
            <li :class="index === 5 ? 'active' : ''">
              <a href="/CooperatePartner">合作伙伴</a>
            </li>
            <li :class="index === 6 ? 'active' : ''">
              <a href="/ContactUs">联系我们</a>
            </li>
          </ul>
          <ul v-if="up">
            <li :class="index === 1 ? 'active' : ''">
              <a href="/">首页</a>
            </li>
            <li :class="index === 2 ? 'active' : ''">
              <a href="/CompanyProfile">公司介绍</a>
            </li>
            <li :class="index === 3 || index === 8 ? 'active' : ''">
              <a href="/ProductServices">产品服务</a>
            </li>
            <li :class="index === 4 ? 'active' : ''">
              <a href="/SuccessAnli">成功案例</a>
            </li>
            <li :class="index === 5 ? 'active' : ''">
              <a href="/CooperatePartner">合作伙伴</a>
            </li>
            <li :class="index === 6 ? 'active' : ''">
              <a href="/ContactUs">联系我们</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="index !== 1" class="title">
      <div class="container title-content">
        <span style="font-weight: 700">
          {{ text[index] }}
        </span>
        <div>
          <a href="/" style="color: #ff0000">首页</a
          ><span style="margin: 0 0.0617rem">/</span>
          <span>{{ text[index] }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["index"],
  data() {
    return {
      up: false,
      one: false,
      two: false,
      three: false,
      four: false,
      five: false,
      six: false,
      text: {
        2: "公司介绍",
        3: "产品服务",
        4: "成功案例",
        5: "合作伙伴",
        6: "联系我们",
        7: "工作之余",
        8: "产品详情",
      },
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.container {
  width: 7.2222rem;
  // background-color: rgb(186 , 186, 235);
  margin: 0 auto;
}
.navTop {
  width: 100%;
  // height: 0.6173rem;
  // background-color: pink;
  .nav-top-box {
    // height: 0.6173rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .an {
      width: 100%;
      justify-content: end;
      display: none;
      img {
        width: 43px;
        height: 33px;
      }
    }
    ul {
      font-size: 0.0864rem;
      display: flex;
      width: 3.0864rem;
      justify-content: space-between;
      align-items: center;
      .active {
        background-color: #cd0007;
        color: #fff;
      }
      li {
        display: flex;
        color: #555;
        font-weight: 700;
        align-items: center;
        border-radius: 0.0309rem;
        overflow: hidden;
        a {
          display: flex;
          padding: 0.0617rem 0.0617rem;
          height: 100%;
          width: 100%;
        }
        a:hover {
          background-color: #cd0007;
          color: #fff;
        }
      }
    }
  }
}
.title {
  width: 100%;
  height: 0.7716rem;
  background-color: #cd0007;
  margin-bottom: 0.0617rem;
  color: #fff;
  display: flex;
  align-items: center;
  .title-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span:first-child {
      font-size: 0.1481rem;
    }
  }
}
@media (max-width: 768px) {
  .navTop {
    width: 100%;
    .container {
      width: 100% !important;
      .nav-top-box {
        .logo {
          margin-left: 10px;
        }
        .an {
          display: flex;
          padding: 20px;
        }
        ul {
          display: flex;
          flex-direction: column;
          padding: 0 20px;
          width: 100%;
          border-top: 1px solid #eee;
          li {
            margin-top: 10px;
            border-radius: 8px;
            font-size: 14px;
            a {
              padding: 15px;
            }

            width: 100%;
          }
        }
        .hidden {
          display: none;
        }
      }
    }
  }
  .title {
    margin-top: 10px;
    height: 50px;
    .title-content {
      span:first-child {
        font-size: 16px;
      }
    }
  }
}
</style>
