var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('navTopVue',{attrs:{"index":1}}),_c('div',{staticClass:"swiper-container",attrs:{"id":"mySwiper"}},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.banner),function(carousel,index){return _c('div',{key:index,staticClass:"swiper-slide"},[_c('img',{attrs:{"src":carousel.imgUrl,"width":"100%"}})])}),0),_c('div',{staticClass:"swiper-pagination"}),_c('div',{staticClass:"swiper-button-prev"}),_c('div',{staticClass:"swiper-button-next"})]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"top",class:{ active: _vm.one },on:{"mousemove":function($event){_vm.one = true},"mouseout":function($event){_vm.one = false}}},[_c('img',{attrs:{"src":_vm.one
                ? require('../assets/img/1.png')
                : require('../assets/img/2.png')}}),_c('a',{staticStyle:{"margin-top":"0.1852rem"},attrs:{"href":"/ProductDetails"}},[_vm._v("政府机关人事管理软件")])]),_vm._m(0)]),_vm._m(1),_c('div',{staticClass:"item"},[_c('div',{staticClass:"top",class:{ active: _vm.three },on:{"mousemove":function($event){_vm.three = true},"mouseout":function($event){_vm.three = false}}},[_c('img',{attrs:{"src":_vm.three
                ? require('../assets/img/1.png')
                : require('../assets/img/2.png')}}),_c('a',{staticStyle:{"margin-top":"0.1852rem"},attrs:{"href":"#"}},[_vm._v("政府机关人事管理软件")])]),_vm._m(2)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"top",class:{ active: _vm.four },on:{"mousemove":function($event){_vm.four = true},"mouseout":function($event){_vm.four = false}}},[_c('img',{attrs:{"src":_vm.four
                ? require('../assets/img/1.png')
                : require('../assets/img/2.png')}}),_c('a',{staticStyle:{"margin-top":"0.1852rem"},attrs:{"href":"#"}},[_vm._v("政府机关人事管理软件")])]),_vm._m(3)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"top",class:{ active: _vm.five },on:{"mousemove":function($event){_vm.five = true},"mouseout":function($event){_vm.five = false}}},[_c('img',{attrs:{"src":_vm.five
                ? require('../assets/img/1.png')
                : require('../assets/img/2.png')}}),_c('a',{staticStyle:{"margin-top":"0.1852rem"},attrs:{"href":"#"}},[_vm._v("政府机关人事管理软件")])]),_vm._m(4)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"top",class:{ active: _vm.six },on:{"mousemove":function($event){_vm.six = true},"mouseout":function($event){_vm.six = false}}},[_c('img',{attrs:{"src":_vm.six
                ? require('../assets/img/1.png')
                : require('../assets/img/2.png')}}),_c('a',{staticStyle:{"margin-top":"0.1852rem"},attrs:{"href":"#"}},[_vm._v("政府机关人事管理软件")])]),_vm._m(5)])])]),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_c('footerVue')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bot"},[_c('span',[_vm._v("Ripsoft Paperless Meeting System (RPMS)无纸化会议管理软件能满足企事业单位各类会议需求，尤其在支持普遍使用的圆桌会议方面，具有无可比拟的技术优势。该系统能通过与各类智能化会议设备结合实现会议的实时互动功能。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top active"},[_c('img',{attrs:{"src":require("../assets/img/2.png")}}),_c('a',{staticStyle:{"margin-top":"0.1852rem"},attrs:{"href":"#"}},[_vm._v("政府机关人事管理软件")])]),_c('div',{staticClass:"bot"},[_c('span',[_vm._v("Ripsoft Paperless Meeting System (RPMS)无纸化会议管理软件能满足企事业单位各类会议需求，尤其在支持普遍使用的圆桌会议方面，具有无可比拟的技术优势。该系统能通过与各类智能化会议设备结合实现会议的实时互动功能。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bot"},[_c('span',[_vm._v("Ripsoft Paperless Meeting System (RPMS)无纸化会议管理软件能满足企事业单位各类会议需求，尤其在支持普遍使用的圆桌会议方面，具有无可比拟的技术优势。该系统能通过与各类智能化会议设备结合实现会议的实时互动功能。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bot"},[_c('span',[_vm._v("Ripsoft Paperless Meeting System (RPMS)无纸化会议管理软件能满足企事业单位各类会议需求，尤其在支持普遍使用的圆桌会议方面，具有无可比拟的技术优势。该系统能通过与各类智能化会议设备结合实现会议的实时互动功能。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bot"},[_c('span',[_vm._v("Ripsoft Paperless Meeting System (RPMS)无纸化会议管理软件能满足企事业单位各类会议需求，尤其在支持普遍使用的圆桌会议方面，具有无可比拟的技术优势。该系统能通过与各类智能化会议设备结合实现会议的实时互动功能。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bot"},[_c('span',[_vm._v("Ripsoft Paperless Meeting System (RPMS)无纸化会议管理软件能满足企事业单位各类会议需求，尤其在支持普遍使用的圆桌会议方面，具有无可比拟的技术优势。该系统能通过与各类智能化会议设备结合实现会议的实时互动功能。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wenjian"},[_c('div',{staticClass:"box"},[_c('img',{attrs:{"src":require("../assets//img/property-img-elook.png"),"alt":""}}),_c('div',{staticClass:"right"},[_c('span',[_vm._v("文件管理产品——资源通")]),_c('span',{staticStyle:{"font-size":"0.0864rem"}},[_vm._v("非结构化数据（图片、文档、资料）统一管理")]),_c('a',{staticClass:"a",attrs:{"href":"#"}},[_vm._v("查看详细>>")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"huanjing"},[_c('div',{staticClass:"center"},[_c('span',[_vm._v("办公环境")]),_c('div',{staticClass:"photo"},[_c('img',{attrs:{"src":require("../assets/img/works/img1.jpg"),"alt":""}}),_c('img',{staticStyle:{"margin-left":"0.1235rem"},attrs:{"src":require("../assets/img/works/img2.jpg"),"alt":""}}),_c('img',{staticStyle:{"margin-left":"0.1235rem"},attrs:{"src":require("../assets/img/works/img3.jpg"),"alt":""}}),_c('img',{staticStyle:{"margin-left":"0.1235rem"},attrs:{"src":require("../assets/img/works/img4.jpg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"guanyu"},[_c('div',{staticClass:"box"},[_c('span',{staticStyle:{"font-weight":"700"}},[_vm._v("关于我们")]),_c('div',{staticClass:"jieshao"},[_c('p',{staticStyle:{"text-align":"left"}},[_vm._v(" 广东政创软件科技有限公司（GuangDong Ripsoft Co.,LTD）位于东莞市创业产业中心园区，公司注册资本1200万元人民币，公司主要业务方向包括：系统集成服务、软件开发、软件外包业务。其中系统集成面向政府和企业提供包括楼宇智能、机房建设、软硬件设备集成等服务，软件开发主要面对区域性政府的信息化应用系统开发；软件外包服务主要是承接神州数码、华为和广东省移动的软件外包服务。 ")]),_c('a',{staticClass:"a",attrs:{"href":"#"}},[_vm._v("查看详细>>")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hezuo"},[_c('img',{attrs:{"src":require("../assets/img/company/img_51.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/img/company/img_56.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/img/company/img_59.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/img/company/img_61.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/img/company/img_67.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/img/company/img_70.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/img/company/img_72.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/img/company/img_74.png"),"alt":""}})])
}]

export { render, staticRenderFns }