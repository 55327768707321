<template>
  <div>
    <div class="card">
      <p>{{ luyouList[string].title }}</p>
      <a href="#" v-for="(item, ind) in luyouList[string].img" :key="ind"
        ><img :src="item" alt=""
      /></a>
    </div>
  </div>
</template>

<script>
export default {
  props: ["string"],
  data() {
    return {
      luyouList: {
        all: {
          title: "工作之余",
          img: [
            require("../assets/img/visit/ht_wuhan.png"),
            require("../assets/img/visit/yangshuo.png"),
            require("../assets/img/visit/danxiashan.png"),
            require("../assets/img/visit/wys.jpg"),
            require("../assets/img/visit/jx.jpg"),
            require("../assets/img/visit/hn_01.jpg"),
            require("../assets/img/visit/hz.jpg"),
            require("../assets/img/visit/ty.jpg"),
            require("../assets/img/visit/nh.jpg"),
            require("../assets/img/visit/02.jpg"),
          ],
        },
        wuhan: {
          title: "武汉之旅",
          img: [
            require("../assets/img/tour/wuhan1.png"),
            require("../assets/img/tour/wuhan2.png"),
            require("../assets/img/tour/wuhan3.png"),
          ],
        },
        yangshuo: {
          title: "阳朔之旅",
          img: [
            require("../assets/img/tour/yangshuo_bottom.png"),
            require("../assets/img/tour/yangshuo_middel.png"),
            require("../assets/img/tour/yangshuo_top.png"),
          ],
        },
        shaoguan: {
          title: "韶关之旅",
          img: [
            require("../assets/img/tour/frist_img.png"),
            require("../assets/img/tour/second_img.png"),
            require("../assets/img/tour/third_img.png"),
          ],
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 7.2222rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 0.3086rem;
  a img {
    width: 100%;
    margin-top: 0.1235rem;
  }
  p {
    font-size: 0.1481rem;
    color: #666;
    margin-top: 0.1235rem;
  }
}
@media (max-width: 768px) {
  .card {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    a img {
      margin-top: 10px;
    }
    p {
      font-size: 16px;
    }
  }
}
</style>
