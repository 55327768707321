<template>
  <div>
    <navTopVue :index="8"></navTopVue>

    <!-- 产品详情 -->
    <div class="card">
      <span class="title">电子政务并联审批系统</span>
      <span class="line"></span>
      <div class="content">
        <img src="../assets/img/product/soft3.jpg" alt="" />
        <div class="text">
          <span>系统组成</span>
          <p>
            1、流程动态管理子系统，可根据各部门业务动态定制审批流程，实现多部门、多流程、多方式的审批流程设置，可以动态修改流程，支持审批流程优化。
          </p>
          <p>
            2、绩效监督子系统，提供项目实时跟踪，实施过程监控，提供统计报表，方便上级监督和绩效评估。。
          </p>
          <p>
            2、绩效监督子系统，提供项目实时跟踪，实施过程监控，提供统计报表，方便上级监督和绩效评估。。
          </p>
          <p>
            5、分布式部署管理子系统，系统支持分布式部署方式，以减轻核心服务器负载，提供个性化服务，为此开发了易于操作界面友好的子系统，以便于核心数据与各部门分布式数据的部署与规则配置
          </p>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <footerVue></footerVue>
  </div>
</template>

<script>
import navTopVue from "@/components/navTopVue.vue";
import footerVue from "@/components/footerVue.vue";
export default {
  components: {
    navTopVue,
    footerVue,
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 4.8148rem;
  margin: 0.1235rem auto;
  padding-bottom: 0.1235rem;
  .title {
    font-size: 0.1481rem;
    color: #666;
  }
  .line {
    border: dashed 1px #e5e5e5;
    width: 100%;
    display: block;
    margin-top: 0.0309rem;
  }
  .content {
    padding: 0 0.1389rem;
    img {
      width: 100%;
      margin-top: 0.1235rem;
    }
    .text {
      text-align: left;
      line-height: 0.1543rem;
      margin-top: 0.1235rem;
      color: #000;
      span {
        font-size: 0.0864rem;
        font-weight: 700;
      }
      p {
        text-indent: 2em;
      }
    }
  }
}
@media (max-width: 768px) {
  .card {
    box-sizing: border-box;
    width: 100%;
    .title {
      font-size: 16px;
    }
    .line {
      margin-top: 10px;
    }
    .content {
      padding: 10px;
      .text {
        line-height: 20px;
        font-size: 12px;
      }
    }
  }
}
</style>
