import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import CompanyProfile from "../views/CompanyProfile.vue";
import JobsAfterAll from "../views/JobsAfterAll.vue";
import ProductServices from "../views/ProductServices.vue";
import ProductDetails from "../views/ProductDetails.vue";
import SuccessAnli from "../views/SuccessAnli.vue";
import CooperatePartner from "../views/CooperatePartner.vue";
import ContactUs from "../views/ContactUs.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView, //首页
  },
  {
    path: "/CompanyProfile",
    name: "CompanyProfile",
    component: CompanyProfile, //公司介绍
  },
  {
    path: "/JobsAfterAll",
    name: "JobsAfterAll",
    component: JobsAfterAll, //工作之余
  },
  {
    path: "/ProductServices",
    name: "ProductServices",
    component: ProductServices, //产品服务
  },
  {
    path: "/ProductDetails",
    name: "ProductDetails",
    component: ProductDetails, //产品详情
  },
  {
    path: "/SuccessAnli",
    name: "SuccessAnli",
    component: SuccessAnli, //成功案例
  },
  {
    path: "/CooperatePartner",
    name: "CooperatePartner",
    component: CooperatePartner, //合作伙伴
  },
  {
    path: "/ContactUs",
    name: "ContactUs",
    component: ContactUs, //联系我们
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
