<template>
  <div>
    <navTopVue :index="5"></navTopVue>

    <!-- 产品详情 -->
    <div class="card">
      <p>
        我们携手IT行业国内外知名厂商，为客户提供一流的产品和服务。目前，我公司已成为IBM、Oracle、曙光、金蝶、微软等厂商相关产品政府协议供货商，成为Polycom、H3C、Cisco、华为、锐捷等厂商区域市场核心合作伙伴
      </p>
      <img src="../assets/img/company/2.jpg" alt="" />
    </div>

    <!-- 底部 -->
    <footerVue></footerVue>
  </div>
</template>

<script>
import navTopVue from "@/components/navTopVue.vue";
import footerVue from "@/components/footerVue.vue";
export default {
  components: {
    navTopVue,
    footerVue,
  },
  data() {
    return {
      page: 1,
    };
  },
};
</script>
<style lang="scss" scoped>
.card {
  width: 4.8148rem;
  height: 2.358rem;
  margin: 0 auto;
  padding-top: 0.1235rem;
  padding-bottom: 0.3086rem;
  p {
    text-indent: 2em;
    font-size: 0.0864rem;
    text-align: left;
    line-height: 0.1358rem;
  }
  img {
    margin-top: 0.0617rem;
    width: 100%;
    border: solid 1px #f2f2f2;
    box-shadow: 3px 3px 5px #f2f2f2;
  }
}
@media (max-width: 768px) {
  .card {
    width: 100%;
    height: 300px;
    p {
      padding: 10px;
      line-height: 20px;
      font-size: 14px;
    }
  }
}
</style>
