import { render, staticRenderFns } from "./luYouVue.vue?vue&type=template&id=728f1664&scoped=true&"
import script from "./luYouVue.vue?vue&type=script&lang=js&"
export * from "./luYouVue.vue?vue&type=script&lang=js&"
import style0 from "./luYouVue.vue?vue&type=style&index=0&id=728f1664&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "728f1664",
  null
  
)

export default component.exports