<template>
  <div>
    <navTopVue :index="3"></navTopVue>

    <!-- 产品服务 -->
    <div :class="i % 2 !== 0 ? '' : 'active'" v-for="i in 10" :key="i">
      <div class="box">
        <div class="left">
          <div class="text">
            <span>专业技术人才管理系统</span>
            <a href="/ProductDetails">
              以我市现有电子政务网络基础设施为依托，在电子政务发展的基础上，
              以应用需求为导向，综合运用计算机及现代通信技术，建立人才综合管理平台，
              实现人才普查、人才预测、专业技术人员管理、高层次人才管理等功能，
              持续提高我市人事管理的现代化管理水平。为人力资源统计和信息化建设工作提供信息和技术支撑
            </a>
            <a href="/ProductDetails">更多>></a>
          </div>
        </div>
        <div class="right">
          <img src="../assets/img/product/comm.jpg" alt="" />
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <footerVue></footerVue>
  </div>
</template>

<script>
import navTopVue from "@/components/navTopVue.vue";
import footerVue from "@/components/footerVue.vue";
export default {
  components: {
    navTopVue,
    footerVue,
  },
};
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  width: 7.2222rem;
  margin: 0 auto;
  padding: 0.3086rem 0;
  justify-content: space-between;
  .left {
    .text {
      width: 3.6111rem;
      text-align: left;
      display: flex;
      flex-direction: column;
      span {
        font-size: 0.1481rem;
        color: #666;
        line-height: 0.2716rem;
      }
      a {
        line-height: 0.1543rem;
        color: #999;
        margin: 0.0617rem 0;
        font-size: 0.0802rem;
      }
      a:last-child {
        margin: 0.0617rem 0;
        font-size: 0.0802rem;
        color: #cd0007;
      }
    }
  }
  .right {
    width: 3.4259rem;
    img {
      width: 3.4259rem;
    }
  }
}
.active {
  background-color: #f2f2f2;
  width: 100%;
}
@media (max-width: 768px) {
  .box {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    flex-direction: column;
    .left {
      .text {
        width: 100%;
        span {
          line-height: 30px;
        }
        a {
          line-height: 20px;
        }
      }
    }
    .right {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
}
</style>
