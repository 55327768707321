<template>
  <div>
    <navTopVue :index="2"></navTopVue>

    <!-- 公司详情 -->
    <div class="card jianjie">
      <img src="../assets/img/about_ritsoft.png" alt="" />
      <div>
        <b>山东乘风科技有限公司</b>
        <p>
          山东乘风科技有限公司（GuangDong Ripsoft
          Co.,LTD）位于东莞市创业产业中心园区，公司注册资本1200万元人民币，公司主要业务方向包括：系统集成服务、软件开发、软件外包业务。其中系统集成面向政府和企业提供包括楼宇智能、机房建设、软硬件设备集成等服务，软件开发主要面对区域性政府的信息化应用系统开发；软件外包服务主要是承接神州数码、华为和广东省移动的软件外包服务。
        </p>
        <p>
          经过多年的发展，公司逐步完善形成了一套科学的、适应市场与企业发展的经营管理机制。在现代信息技术领域的激烈市场竞争中，公司凭借一批优秀的经营管理人才和成功的经营管理机制，与我国的IT市场共同发展，取得了华南地区行业领先的市场地位。在为用户提供优质技术服务同时，也以独特的服务理念、良好的信誉和优质的技术赢得了客户的信赖。
        </p>
        <p>
          公司在注重品质和服务的同时也致力于构建一个融合、开放的人才平台，为那些勇担责任、激情无限、敢于创新的业界精英提供充分的发展空间，积极营造追求卓越、积极奉献的工作氛围，优雅自信、从容自得的工作环境。并通过每个员工不断提升自我，以自己的独特价值观对工作与生活做最准确的判断，使我们每一个员工彰显出他们出色的自我品位，独有的工作个性和卓越的创新风格，让他们时刻保持振奋、不断鼓舞内心深处的梦想，永远走在时代潮流前端。
        </p>
        <p>
          公司现有技术人员近40人，其中约20%为硕士学历，90%为大学本科及本科以上学历，其中核心技术人员均具备5年以上系统集成及软件开发经验和规模团队管理经历，9人具有项目经理资质，其中2人具备高级项目经理资质。在软件开发及外包方面已形成以J2EE、.NET和C++等开发语言为技术方向的多个项目开发团队,熟悉ORACLE、SQL
          SERVER、DB2、SYBASE等主流数据库，熟悉多种数据交换、门户、报表展现、决策分析等的商业产品，自行开发了多套具有自主知识产权的通用软件平台及产品，可根据客户需求构建应用系统。在系统集成方面已形成楼宇智能化、数据机房、信息安全、设备调试及维保等四个专业化技术团队，有能力承接各类系统集成项目。
        </p>
      </div>
    </div>

    <!--轮播和 公司文化 -->
    <div class="gray-box">
      <div class="card wenhua">
        <div class="swiper-container lunbo" id="mySwiper">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in bannar"
              :key="index"
            >
              <div class="item">
                <img :src="item.imgUrl" width="100%" />
                <div class="name">
                  <span style="color: #504946">{{ item.name }}</span>
                  <p style="color: #ababab">{{ item.zhiwei }}</p>
                </div>
              </div>
              <div class="neirong">
                <div class="triangle"></div>
                <p>{{ item.content }}</p>
              </div>
            </div>
          </div>

          <div class="swiper-button-prev" style="color: #ababab"></div>
          <div class="swiper-button-next" style="color: #ababab"></div>
        </div>
        <div class="right">
          <p>公司文化</p>
          <span
            >A.责任：对客户负责是所有政创员工和合作伙伴必须秉承的价值理念。</span
          >
          <span
            >A.责任：对客户负责是所有政创员工和合作伙伴必须秉承的价值理念。</span
          >
          <span
            >A.责任：对客户负责是所有政创员工和合作伙伴必须秉承的价值理念。</span
          >
          <span
            >A.责任：对客户负责是所有政创员工和合作伙伴必须秉承的价值理念。</span
          >
        </div>
      </div>
    </div>

    <!-- 企业资质企业荣誉 -->
    <div class="zizhi">
      <div class="card zizhi-box">
        <div class="left">
          <p>企业资质</p>
          <div class="swiper-container lunbo2" id="mySwiper">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide zizhi-img"
                v-for="(item, index) in bannar2"
                :key="index"
              >
                <div
                  class="item"
                  v-for="(item2, ind) in item.chrild"
                  :key="ind"
                >
                  <img :src="item2.imgUrl" width="100%" />
                  <span style="color: #504946">{{ item2.name }}</span>
                </div>
              </div>
            </div>
            <div class="swiper-button-prev" style="color: #ababab"></div>
            <div class="swiper-button-next" style="color: #ababab"></div>
          </div>
        </div>
        <div class="right">
          <p>企业荣誉</p>
          <img src="../assets/img/honor/hon_1.jpg" alt="" />
        </div>
      </div>
    </div>

    <!-- 工作之余 -->
    <div class="card gongzuo">
      <div class="gongzuo_title">
        <span>工作之余</span>
        <a href="/JobsAfterAll?string=all">更多</a>
      </div>
      <div>
        <a href="/JobsAfterAll?string=wuhan"
          ><img src="../assets/img/visit/ht_wuhan.png" alt=""
        /></a>
        <a href="/JobsAfterAll?string=yangshuo"
          ><img src="../assets/img/visit/yangshuo.png" alt=""
        /></a>
      </div>
    </div>

    <!-- 底部 -->
    <footerVue></footerVue>
  </div>
</template>

<script>
import navTopVue from "@/components/navTopVue.vue";
import footerVue from "@/components/footerVue.vue";
import Swiper from "swiper";
import "swiper/css/swiper.css";
export default {
  components: {
    navTopVue,
    footerVue,
  },
  data() {
    return {
      bannar: [
        {
          imgUrl: require("../assets/img/touxiang.png"),
          name: "王勇",
          zhiwei: "副总经理",
          content:
            "广东政创软件科技有限公司（GuangDong Ripsoft Co.,LTD）位于东莞市创业产业中心园区，公司注册资本1200万元人民币，公司主要业务方向包括：系统集成服务、软件开发、软件外包业务。其中系统集成面向政府和企业提供包括楼宇智能、机房建设、软硬件设备集成等服务，软件开发主要面对区域性政府的信息化应用系统开发；软件外包服务主要是承接神州数码、华为和广东省移动的软件外包服务。",
        },
        {
          imgUrl: require("../assets/img/touxiang.png"),
          name: "李欢",
          zhiwei: "总经理",
          content:
            "广东政创软件科技有限公司（GuangDong Ripsoft Co.,LTD）位于东莞市创业产业中心园区，公司注册资本1200万元人民币，公司主要业务方向包括：系统集成服务、软件开发、软件外包业务。其中系统集成面向政府和企业提供包括楼宇智能、机房建设、软硬件设备集成等服务，软件开发主要面对区域性政府的信息化应用系统开发；软件外包服务主要是承接神州数码、华为和广东省移动的软件外包服务。",
        },
      ],
      bannar2: [
        {
          chrild: [
            {
              imgUrl: require("../assets/img/honor/jc_2021.jpg"),
              name: "信息系统集成及服务资质",
            },
            {
              imgUrl: require("../assets/img/honor/3A_2017.jpg"),
              name: "AAA信用企业",
            },
          ],
        },
        {
          chrild: [
            {
              imgUrl: require("../assets/img/honor/iso20000.jpg"),
              name: "ISO/IEC 2000信息技术服务管理体系认证",
            },
            {
              imgUrl: require("../assets/img/honor/iso27001.jpg"),
              name: "ISO/IEC 27001信息安全管理体系认证",
            },
          ],
        },
        {
          chrild: [
            {
              imgUrl: require("../assets/img/honor/iso9001.jpg"),
              name: "ISO 9001质量管理体系认证",
            },
            {
              imgUrl: require("../assets/img/honor/hon_12.jpg"),
              name: "NQA认证",
            },
          ],
        },
        {
          chrild: [
            {
              imgUrl: require("../assets/img/honor/hon_7.jpg"),
              name: "软件著作权",
            },
            {
              imgUrl: require("../assets/img/honor/hon_6.jpg"),
              name: "软件著作权",
            },
          ],
        },
        {
          chrild: [
            {
              imgUrl: require("../assets/img/honor/env.jpg"),
              name: "环境管理体系认证",
            },
            {
              imgUrl: require("../assets/img/honor/job.jpg"),
              name: "职业健康安全管理体系认证",
            },
          ],
        },
      ],
    };
  },

  methods: {},
  watch: {
    banner: {
      handler() {
        //只能监听到轮播图数据已经有了，但是v-for动态渲染结构我们还是没有办法确定的，因此还是需要用nextTick
        //nextTick:  在下次 DOM 更新循环结束之后执行延迟回调。在修改数据之后立即使用这个方法，获取更新后的 DOM
        this.$nextTick(() => {
          new Swiper(".swiper-container", {
            loop: true, // 循环模式选项
            // 如果需要分页器
            pagination: {
              el: ".swiper-pagination",
              //点击小球的时候也切换图片
              clickable: true,
            },
            autoplay: {
              delay: 3000,
              disableOnInteration: false,
            },
            // 如果需要前进后退按钮
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
            // 如果需要滚动条
            scrollbar: {
              el: ".swiper-scrollbar",
            },
          });
        });
      },
      //立即监听：不管你数据有没有变化，我上来立即监听一次
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 7.2222rem;
  margin: 0 auto;
}
.jianjie {
  // height: 3.0556rem;
  display: flex;
  text-align: left;
  padding: 0.1852rem 0;
  b {
    font-size: 0.1481rem;
    color: #666;
    margin-left: 30px;
  }
  img {
    width: 2.821rem;
    height: 2.679rem;
  }
  p {
    text-align: left;
    font-size: 0.0802rem;
    line-height: 0.1358rem;
    margin-top: 0.0617rem;
    margin-left: 0.1852rem;
  }
}

.gray-box {
  width: 100%;
  height: 2.5617rem;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 0.0864rem !important;
    color: #ccc;
  }
  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    left: 2.3457rem;
    top: 0.0617rem;
    right: auto;
  }
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: 0.1235rem;
    top: 0.0617rem;
    left: auto;
  }
}
.wenhua {
  display: flex;
  text-align: left;
  justify-content: space-between;
  .lunbo {
    width: 2.8272rem;
    height: 1.716rem;
    .item {
      display: flex;
      align-items: center;
      img {
        width: 0.6173rem;
        height: 0.6173rem;
        border-radius: 50%;
      }
      .name {
        margin-left: 0.1235rem;
      }
    }
    .neirong {
      position: relative;
      line-height: 20px;
      padding: 0.1235rem;
      margin-top: 0.1235rem;
      // height: 0.9938rem;
      background-color: #e9e9e9;
      .triangle {
        position: absolute;
        top: -0.0617rem;
        left: 0.1852rem;
        width: 0;
        height: 0;
        border-left: 0.0926rem solid transparent;
        border-right: 0.0926rem solid transparent;
        border-bottom: 0.0926rem solid #e9e9e9;
      }
    }
  }

  .right {
    width: 4.2099rem;
    height: 1.6481rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    p {
      font-size: 0.1235rem;
      color: #47506a;
    }
    span {
      background-color: #ff0000;
      font-size: 0.0741rem;
      padding: 0.0617rem;
      color: #fff;
      border-radius: 0.0309rem;
    }
  }
}
.zizhi {
  margin-top: 0.1235rem;
  width: 100%;
  background-color: #f2f2f2;
  .zizhi-box {
    display: flex;
    height: 3.1481rem;
    align-items: center;
    .swiper-button-prev:after,
    .swiper-button-next:after {
      font-size: 0.0864rem !important;
      color: #000;
    }
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      left: 0.0617rem;
      //   top: 1.2346rem;
      z-index: 5;
    }
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      right: 0.0617rem;
    }

    .left {
      width: 4.216rem;
      p {
        font-size: 0.1481rem;
        text-align: left;
        padding-left: 0.1235rem;
        color: #666;
        margin-bottom: 0.1235rem;
      }
      .lunbo2 {
        width: 4.2099rem;
        height: 2.1605rem;
      }
      .item {
        display: flex;
        flex-direction: column;
        padding-bottom: 0.0617rem;
        img {
          width: 2.0123rem;
          margin: 0 0.0494rem;
        }
      }
      .zizhi-img {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .right {
      width: 2.8272rem;
      display: flex;
      flex-direction: column;
      p {
        font-size: 0.1481rem;
        text-align: left;
        padding-left: 0.1235rem;
        color: #666;
        margin-bottom: 0.1235rem;
      }
      img {
        width: 2.2716rem;
        height: 1.7716rem;
      }
    }
  }
}
.gongzuo {
  height: 2.7654rem;
  margin-top: 0.1235rem;
  margin-bottom: 0.3086rem;
  img {
    width: 6.6049rem;
    margin-top: 20px;
  }
  .gongzuo_title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    span {
      font-size: 0.1605rem;
    }
    a {
      font-size: 0.0988rem;
    }
    a:hover {
      color: #ffd101;
    }
  }
}
@media (max-width: 768px) {
  .card {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .jianjie {
    flex-direction: column;
    padding: 10px;
    b {
      font-size: 20px;
    }
    img {
      width: 100%;
      height: 300px;
    }
    p {
      margin-top: 10px;
      font-size: 12px;
      line-height: 20px;
    }
  }

  .gray-box {
    height: 580px;
    .swiper-button-prev:after,
    .swiper-button-next:after {
      font-size: 14px !important;
      color: #ccc;
    }
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      left: 200px;
      top: 10px;
      right: auto;
    }
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      right: 20px;
      top: 10px;
      left: auto;
    }
  }
  .wenhua {
    flex-direction: column;
    .lunbo {
      width: 100%;
      height: 300px;
      .item {
        img {
          width: 100px;
          height: 100px;
        }
      }
      .neirong {
        margin-top: 20px;
        .triangle {
          top: -14px;
          left: 20px;
          border-left: 15px solid transparent;
          border-right: 15px solid transparent;
          border-bottom: 15px solid #e9e9e9;
        }
      }
    }
    .right {
      width: 100%;
      height: 250px;
      p {
        font-size: 16px;
        color: #47506a;
      }
      span {
        font-size: 12px;
        border-radius: 5px;
        padding: 5px;
      }
    }
  }
  .zizhi {
    margin-top: 10px;
    .zizhi-box {
      height: 510px;
      width: 100%;
      flex-direction: column;
      .left {
        margin-top: 20px;
        width: 100%;
        p {
          font-size: 14px;
        }
        .lunbo2 {
          width: 100%;
          height: 195px;
        }
        .item {
          span {
            padding: 0 5px;
          }
          img {
            width: 155px;
            margin: 0 5px;
          }
        }
      }
      .right {
        width: 100%;
        p {
          margin-top: 20px;
          font-size: 14px;
        }
        img {
          width: 100%;
          height: 180px;
        }
      }
    }
  }
  .gongzuo {
    height: 250px;
    margin-top: 20px;
    img {
      width: 100%;
      height: 80px;
      margin-top: 20px;
    }
    .gongzuo_title {
      span {
        font-size: 16px;
      }
      a {
        font-size: 14px;
      }
    }
  }
}
</style>
