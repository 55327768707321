<template>
  <div class="">
    <navTopVue :index="1"></navTopVue>
    <!-- 轮播图 -->

    <div class="swiper-container" id="mySwiper">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(carousel, index) in banner"
          :key="index"
        >
          <img :src="carousel.imgUrl" width="100%" />
        </div>
      </div>
      <div class="swiper-pagination"></div>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>

    <!-- 主体 -->
    <div class="body">
      <div class="box">
        <div class="item">
          <div
            class="top"
            v-bind:class="{ active: one }"
            @mousemove="one = true"
            @mouseout="one = false"
          >
            <img
              :src="
                one
                  ? require('../assets/img/1.png')
                  : require('../assets/img/2.png')
              "
            />
            <a href="/ProductDetails" style="margin-top: 0.1852rem"
              >政府机关人事管理软件</a
            >
          </div>
          <div class="bot">
            <span
              >Ripsoft Paperless Meeting System
              (RPMS)无纸化会议管理软件能满足企事业单位各类会议需求，尤其在支持普遍使用的圆桌会议方面，具有无可比拟的技术优势。该系统能通过与各类智能化会议设备结合实现会议的实时互动功能。</span
            >
          </div>
        </div>
        <!-- 2 -->
        <div class="item">
          <div class="top active">
            <img src="../assets/img/2.png" />
            <a href="#" style="margin-top: 0.1852rem">政府机关人事管理软件</a>
          </div>
          <div class="bot">
            <span
              >Ripsoft Paperless Meeting System
              (RPMS)无纸化会议管理软件能满足企事业单位各类会议需求，尤其在支持普遍使用的圆桌会议方面，具有无可比拟的技术优势。该系统能通过与各类智能化会议设备结合实现会议的实时互动功能。</span
            >
          </div>
        </div>
        <!-- 3 -->
        <div class="item">
          <div
            class="top"
            v-bind:class="{ active: three }"
            @mousemove="three = true"
            @mouseout="three = false"
          >
            <img
              :src="
                three
                  ? require('../assets/img/1.png')
                  : require('../assets/img/2.png')
              "
            />
            <a href="#" style="margin-top: 0.1852rem">政府机关人事管理软件</a>
          </div>
          <div class="bot">
            <span
              >Ripsoft Paperless Meeting System
              (RPMS)无纸化会议管理软件能满足企事业单位各类会议需求，尤其在支持普遍使用的圆桌会议方面，具有无可比拟的技术优势。该系统能通过与各类智能化会议设备结合实现会议的实时互动功能。</span
            >
          </div>
        </div>
        <!-- 4 -->
        <div class="item">
          <div
            class="top"
            v-bind:class="{ active: four }"
            @mousemove="four = true"
            @mouseout="four = false"
          >
            <img
              :src="
                four
                  ? require('../assets/img/1.png')
                  : require('../assets/img/2.png')
              "
            />
            <a href="#" style="margin-top: 0.1852rem">政府机关人事管理软件</a>
          </div>
          <div class="bot">
            <span
              >Ripsoft Paperless Meeting System
              (RPMS)无纸化会议管理软件能满足企事业单位各类会议需求，尤其在支持普遍使用的圆桌会议方面，具有无可比拟的技术优势。该系统能通过与各类智能化会议设备结合实现会议的实时互动功能。</span
            >
          </div>
        </div>
        <!-- 5 -->
        <div class="item">
          <div
            class="top"
            v-bind:class="{ active: five }"
            @mousemove="five = true"
            @mouseout="five = false"
          >
            <img
              :src="
                five
                  ? require('../assets/img/1.png')
                  : require('../assets/img/2.png')
              "
            />
            <a href="#" style="margin-top: 0.1852rem">政府机关人事管理软件</a>
          </div>
          <div class="bot">
            <span
              >Ripsoft Paperless Meeting System
              (RPMS)无纸化会议管理软件能满足企事业单位各类会议需求，尤其在支持普遍使用的圆桌会议方面，具有无可比拟的技术优势。该系统能通过与各类智能化会议设备结合实现会议的实时互动功能。</span
            >
          </div>
        </div>
        <!-- 6 -->
        <div class="item">
          <div
            class="top"
            v-bind:class="{ active: six }"
            @mousemove="six = true"
            @mouseout="six = false"
          >
            <img
              :src="
                six
                  ? require('../assets/img/1.png')
                  : require('../assets/img/2.png')
              "
            />
            <a href="#" style="margin-top: 0.1852rem">政府机关人事管理软件</a>
          </div>
          <div class="bot">
            <span
              >Ripsoft Paperless Meeting System
              (RPMS)无纸化会议管理软件能满足企事业单位各类会议需求，尤其在支持普遍使用的圆桌会议方面，具有无可比拟的技术优势。该系统能通过与各类智能化会议设备结合实现会议的实时互动功能。</span
            >
          </div>
        </div>
      </div>
    </div>

    <!-- 文件管理 -->
    <div class="wenjian">
      <div class="box">
        <img src="../assets//img/property-img-elook.png" alt="" />
        <div class="right">
          <span>文件管理产品——资源通</span>
          <span style="font-size: 0.0864rem"
            >非结构化数据（图片、文档、资料）统一管理</span
          >
          <a href="#" class="a">查看详细>></a>
        </div>
      </div>
    </div>

    <!-- 环境 -->
    <div class="huanjing">
      <div class="center">
        <span>办公环境</span>
        <div class="photo">
          <img src="../assets/img/works/img1.jpg" alt="" />
          <img
            style="margin-left: 0.1235rem"
            src="../assets/img/works/img2.jpg"
            alt=""
          />
          <img
            style="margin-left: 0.1235rem"
            src="../assets/img/works/img3.jpg"
            alt=""
          />
          <img
            style="margin-left: 0.1235rem"
            src="../assets/img/works/img4.jpg"
            alt=""
          />
        </div>
      </div>
    </div>

    <!-- 关于我们 -->
    <div class="guanyu">
      <div class="box">
        <span style="font-weight: 700">关于我们</span>
        <div class="jieshao">
          <p style="text-align: left">
            广东政创软件科技有限公司（GuangDong Ripsoft
            Co.,LTD）位于东莞市创业产业中心园区，公司注册资本1200万元人民币，公司主要业务方向包括：系统集成服务、软件开发、软件外包业务。其中系统集成面向政府和企业提供包括楼宇智能、机房建设、软硬件设备集成等服务，软件开发主要面对区域性政府的信息化应用系统开发；软件外包服务主要是承接神州数码、华为和广东省移动的软件外包服务。
          </p>
          <a href="#" class="a">查看详细>></a>
        </div>
      </div>
    </div>

    <!-- 合作伙伴 -->
    <div class="hezuo">
      <img src="../assets/img/company/img_51.png" alt="" />
      <img src="../assets/img/company/img_56.png" alt="" />
      <img src="../assets/img/company/img_59.png" alt="" />
      <img src="../assets/img/company/img_61.png" alt="" />
      <img src="../assets/img/company/img_67.png" alt="" />
      <img src="../assets/img/company/img_70.png" alt="" />
      <img src="../assets/img/company/img_72.png" alt="" />
      <img src="../assets/img/company/img_74.png" alt="" />
    </div>
    <!-- 底部 -->
    <footerVue></footerVue>
  </div>
</template>
<script>
import navTopVue from "@/components/navTopVue.vue";
import footerVue from "@/components/footerVue.vue";
import Swiper from "swiper";
import "swiper/css/swiper.css";
export default {
  name: "HomeView",
  data() {
    return {
      one: false,
      two: false,
      three: false,
      four: false,
      five: false,
      six: false,
      banner: [
        { imgUrl: require("../assets/img/banner/slide1.jpg") },
        { imgUrl: require("../assets/img/banner/slide2.jpg") },
        { imgUrl: require("../assets/img/banner/slide3.jpg") },
      ],
    };
  },
  components: {
    navTopVue,
    footerVue,
  },
  methods: {},

  watch: {
    banner: {
      handler() {
        //只能监听到轮播图数据已经有了，但是v-for动态渲染结构我们还是没有办法确定的，因此还是需要用nextTick
        //nextTick:  在下次 DOM 更新循环结束之后执行延迟回调。在修改数据之后立即使用这个方法，获取更新后的 DOM
        this.$nextTick(() => {
          new Swiper(".swiper-container", {
            loop: true, // 循环模式选项
            // 如果需要分页器
            pagination: {
              el: ".swiper-pagination",
              //点击小球的时候也切换图片
              clickable: true,
            },
            // 如果需要前进后退按钮
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
            autoplay: {
              delay: 2500,
              disableOnInteration: false,
            },
            // 如果需要滚动条
            scrollbar: {
              el: ".swiper-scrollbar",
            },
          });
        });
      },
      //立即监听：不管你数据有没有变化，我上来立即监听一次
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.body {
  width: 8.0864rem;
  padding: 0.1235rem 0;
  // background-color: pink;
  margin: 0 auto;
  .box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      margin-top: 0.1235rem;
      width: 2.5062rem;
      height: 2.0864rem;
      display: flex;
      flex-direction: column;
      .top {
        height: 1.2963rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 0.1235rem;
        background: #eee;
        border-radius: 0.0617rem;

        img {
          width: 0.321rem;
          height: 0.321rem;
        }
      }
      .active {
        background: #cd0007;
        color: #fff;
      }
      .bot {
        font-size: 0.0864rem;
        padding: 0.1235rem 0;
        text-align: left;
      }
    }
  }
}
.wenjian {
  height: 3.3765rem;
  width: 100%;
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  .box {
    width: 7.2222rem;
    height: 2.142rem;
    display: flex;

    img {
      height: 1.963rem;
      width: 2.142rem;
    }
    .right {
      display: flex;
      height: 1.2346rem;
      flex-direction: column;
      justify-content: space-between;
      align-items: start;
      margin-left: 0.6173rem;
      span:first-child {
        font-size: 0.1481rem;
      }
      .a {
        width: 0.9753rem;
        height: 0.3765rem;
        line-height: 0.3765rem;
        background-color: #475168;
        color: #fff;
        border-radius: 0.0309rem;
      }
      .a:hover {
        background-color: #cd0007;
      }
    }
  }
}
.huanjing {
  width: 7.2222rem;
  height: 2.4074rem;
  display: flex;
  align-items: center;
  margin: 0 auto;
  .center {
    width: 7.2222rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    span {
      font-size: 0.1481rem;
    }
    .photo {
      margin-top: 0.1235rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      img {
        width: 1.6667rem;
        height: 1.2469rem;
      }
    }
  }
}
.guanyu {
  width: 100%;
  height: 2.4691rem;
  background: url(../assets/img/flat_parallax.jpg);
  font-size: 0.0988rem;
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  .box {
    margin: 0 auto;
    width: 7.2222rem;
    height: 1.9815rem;
    display: flex;
    flex-direction: column;
  }
  .jieshao {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin-top: 0.1235rem;
    p {
      line-height: 0.2469rem;
    }
    .a {
      width: 0.9753rem;
      height: 0.3765rem;
      line-height: 0.3765rem;
      background-color: #ffffff;
      color: #ffc601;
      border-radius: 0.0309rem;
      margin-top: 0.1852rem;
    }
    .a:hover {
      background-color: #cd0007;
      color: #fff;
    }
  }
}
.hezuo {
  width: 7.037rem;
  height: 0.2346rem;
  margin: 0.3086rem auto;
  display: flex;
  justify-content: space-evenly;
}
@media (max-width: 768px) {
  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 14px;
  }
  .body {
    .box {
      flex-direction: column;
      .item {
        width: 100%;
        height: 330px;
        margin-top: 20px;
        justify-content: space-around;
        font-size: 14px;
        line-height: 22px;
        .top {
          height: 210px;
          img {
            width: 53px;
            height: 51px;
          }
        }
      }
    }
  }
  .wenjian {
    margin-top: 20px;
    height: 480px;
    padding: 20px 0;

    .box {
      flex-direction: column;
      height: 450px;
      align-items: center;
      width: 100%;
      img {
        width: 288px;
        height: 239px;
      }
      .right {
        margin-top: 10px;
        margin-left: 0;
        height: 190px;
        span:first-child {
          font-size: 24px;
        }
        .a {
          width: 150px;
          height: 50px;
          line-height: 50px;
          border-radius: 5px;
        }
      }
    }
  }
  .huanjing {
    height: 1000px;
    .center {
      span {
        font-size: 24px;
      }
      .photo {
        margin-top: 20px;
        flex-direction: column;
        height: 900px;
        img {
          width: 270px;
          height: 210px;
        }
      }
    }
  }
  .guanyu {
    height: 399px;
    .box {
      height: 321px;
      span {
        font-size: 24px;
      }
    }
    .jieshao {
      margin-top: 20px;
      p {
        line-height: 25px;
      }
      .a {
        width: 150px;
        height: 50px;
        line-height: 50px;
        border-radius: 5px;
      }
    }
  }
  .hezuo {
    padding: 10px 10px;
    box-sizing: border-box;
    height: 120px;
    width: 100%;
    flex-wrap: wrap;
    img {
      margin-top: 10px;
    }
  }
}
</style>
