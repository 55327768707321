<template>
  <div>
    <navTopVue :index="6"></navTopVue>

    <!-- 产品详情 -->
    <div class="card">
      <div class="left">
        <p>联系我们</p>
        <span>电话 : 0769-1234567</span>
        <span>手机 : 11111111111</span>
        <span>传真：0769 - 123456788</span>
        <span>工作时间：周一至周六 8:30 - 17:30</span>
        <span>地址：山东省淄博市高新区居然之家E座608(创业火炬广场)</span>
      </div>
      <img src="../assets/img/address.png" class="right" />
    </div>

    <!-- 底部 -->
    <footerVue></footerVue>
  </div>
</template>

<script>
import navTopVue from "@/components/navTopVue.vue";
import footerVue from "@/components/footerVue.vue";
export default {
  components: {
    navTopVue,
    footerVue,
  },
  data() {
    return {
      page: 1,
    };
  },
};
</script>
<style lang="scss" scoped>
.card {
  width: 7.2222rem;
  height: 3.0864rem;
  margin: 0 auto;
  margin-top: 0.1235rem;
  display: flex;
  justify-content: space-between;
  .left {
    width: 3.4259rem;
    height: 1.6049rem;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 0.0988rem;
    p {
      font-size: 0.1481rem;
      color: #666;
    }
  }
  .right {
    width: 3.4568rem;
    height: 1.7284rem;
  }
}
@media (max-width: 768px) {
  .card {
    width: 100%;
    flex-direction: column;
    height: 340px;
    padding: 10px;
    box-sizing: border-box;
    .left {
      width: 100%;
      padding: 10px;
    }
    .right {
      width: 100%;
      height: 180px;
    }
  }
}
</style>
