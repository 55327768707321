<template>
  <div>
    <footer class="footer">
      <div class="bottom">
        <div class="content">
          <span>广东政创软件科技有限公司</span>
          <span
            >电话 : 0769-88770177 手机 : 13538353507(李总) 传真 :
            0769-88770556</span
          >
          <span
            >地址:
            广东东莞南城莞太路34号软件创意园8号楼302(南城体育路口公交站旁)
            版权所有：广东政创软件科技有限公司</span
          >
          <span>ICP备案号：粤ICP备19031507号 </span>
        </div>
        <div class="code">
          <span>微信关注我们</span>
          <img src="../assets/img/code/ripcode.jpg" alt="" />
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 1.3827rem;
  background-color: #cd0007;
  display: flex;
  align-items: center;
  .bottom {
    width: 7.037rem;
    height: 0.7407rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    font-size: 0.0864rem;
    color: #fff;
    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .code {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      img {
        width: 0.4938rem;
        height: 0.4938rem;
      }
    }
  }
}
@media (max-width: 768px) {
  .footer {
    height: 280px;
    .bottom {
      height: 230px;
      flex-direction: column;
      .code {
        align-items: center;
        img {
          width: 79px;
          height: 79px;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
