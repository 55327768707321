<template>
  <div>
    <navTopVue :index="7"></navTopVue>

    <!-- 主体 -->
    <luYouVue :string="string"></luYouVue>
    <!-- 底部 -->
    <footerVue></footerVue>
  </div>
</template>

<script>
import navTopVue from "@/components/navTopVue.vue";
import footerVue from "@/components/footerVue.vue";
import luYouVue from "@/components/luYouVue.vue";
export default {
  components: {
    navTopVue,
    footerVue,
    luYouVue,
  },
  data() {
    return {
      string: "",
    };
  },
  created() {
    this.string = this.$route.query.string;
    console.log(this.string);
  },
};
</script>

<style lang="scss" scoped></style>
